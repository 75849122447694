<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.$root.title = "Главная";
  },
  methods: {},
};
</script>
